<script setup lang="ts">
import SideBarItem from "./SideBarItem.vue";
import type { NavigationItems } from "@/types";

interface Props {
  navigation: NavigationItems;
  subNavigation: NavigationItems;
}

defineProps<Props>();
</script>

<template>
  <div class="hidden xl:fixed xl:inset-y-0 xl:z-30 xl:flex xl:w-72 xl:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-light-gray bg-white px-6 py-4">
      <div class="flex h-8 shrink-0 items-center">
        <BaseImage height="2rem" width="144px"  src="/hirable-logo.png" :alt="$config.public.appName"/>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-2">
              <template v-for="item in navigation" :key="item.name">
                <SideBarItem :item="item" />
              </template>
            </ul>
          </li>
          <div class="mt-auto">
            <ul role="list" class="-mx-2 space-y-2">
              <template v-for="item in subNavigation" :key="item.name">
                <SideBarItem :item="item" />
              </template>
            </ul>
          </div>
        </ul>
      </nav>
    </div>
  </div>
</template>
